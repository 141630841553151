import i18next from "i18next";
import { Environment, Language } from "types";

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
export const HEXCODE_REGEX = /^#([A-Fa-f0-9]{3}|[A-Fa-f0-9]{6})$/i;
export const MINUTE_IN_MS = 5 * 60 * 1000;

export const LOCATION_DATA = [
  { label: i18next.t("country.nl"), value: Language.nl },
  { label: i18next.t("country.de"), value: Language.de },
];

export const LANGUAGE_DATA = [
  { label: i18next.t("language.nl"), value: Language.nl },
  { label: i18next.t("language.de"), value: Language.de },
  { label: i18next.t("language.en"), value: Language.en },
];

export const BASE_SHOP_URL =
  import.meta.env.VITE_ENV === Environment.production
    ? "https://shop.valk-togo.nl/"
    : "https://staging.valk-to-go-shop.pages.dev/";
