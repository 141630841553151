import { useQuery } from "@tanstack/react-query";
import { Navigate, Route, Routes } from "react-router-dom";
import api from "services/api";
import { useGroupStore } from "stores/group";
import { UserRoles } from "types";
import AdminRoutes from "utils/AdminRoutes";
import GroupRoutes from "utils/GroupRoutes";
import LocationRoutes from "utils/LocationRoutes";
import PrivateRoutes from "utils/PrivateRoutes";
import AdminLocations from "./locations/AdminLocations";
import PropertyLocations from "./locations/PropertyLocations";
import UserLocations from "./locations/UserLocations";

import { lazy } from "react";

const Login = lazy(() => import("pages/auth/Login"));
const SetPassword = lazy(() => import("@/pages/auth/SetPassword.tsx"));

const AppRoutes = () => {
  const { currentGroup } = useGroupStore();
  const { data: currentUser, isLoading } = useQuery(
    ["currentUser"],
    () => api.account.getCurrentUser(),
    {
      enabled: !!currentGroup,
      select: (data) => data.data,
    }
  );
  return (
    <Routes>
      {/* PrivateRoutes checks whether the user is logged in or not */}
      <Route element={<PrivateRoutes />}>
        {/* GroupRoutes checks whether the user has access to the requested group */}
        <Route path=":groupId" element={<GroupRoutes user={currentUser} loading={isLoading} />}>
          {/* AdminRoutes checks whether the user has a admin role, otherwise redirects to location dashboard */}
          <Route element={<AdminRoutes user={currentUser} loading={isLoading} />}>
            {AdminLocations}
          </Route>

          {/* LocationRoutes checks whether the user has access to the requested location */}
          <Route
            path=":location"
            element={<LocationRoutes user={currentUser} loading={isLoading} />}>
            {currentUser?.role === UserRoles.default ? UserLocations : PropertyLocations}
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Route>
      <Route path="login" element={<Login />} />

      <Route path="set_password" element={<SetPassword />} />

      <Route
        path="*"
        element={currentGroup ? <Navigate to={`/${currentGroup.slug}`} /> : <Navigate to="login" />}
      />
    </Routes>
  );
};
export default AppRoutes;
