import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

import { Group } from "types";

interface GroupStoreProps {
  currentGroup: Group | null;
  setCurrentGroup: (group: Group) => void;
}

const useGroupStore = create<GroupStoreProps>()(
  devtools(
    persist(
      (set) => ({
        currentGroup: null,
        setCurrentGroup: (group) => set(() => ({ currentGroup: group })),
      }),
      { name: "group-storage", storage: createJSONStorage(() => sessionStorage) }
    )
  )
);

export { useGroupStore };
