import { Box, Loader } from "@mantine/core";

import styles from "./Loader.module.scss";
import { ContentLayout } from "components/layout";

const LoaderWithLayout = () => {
  return (
    <ContentLayout>
      <ContentLoader />
    </ContentLayout>
  );
};

const LoaderWithoutLayout = () => {
  return <Box className={styles["loader-background"]} />;
};

const ContentLoader = () => <Loader className={styles.loader} m="xl" />;

export { LoaderWithLayout, LoaderWithoutLayout, ContentLoader };
