import { useMutation } from "@tanstack/react-query";
import api from "services/api";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { NotificationColors } from "types";

const useServerNameMutation = () => {
  const { t } = useTranslation();

  return useMutation(api.priceLabels.config.setup, {
    onSuccess: () => {
      showNotification({
        color: NotificationColors.success,
        title: t("priceLabels.serverNameTitle"),
        message: t("priceLabels.serverNameMessage"),
      });
    },
    onError: () => {
      showNotification({
        color: NotificationColors.error,
        title: t("priceLabels.serverNameError"),
        message: t("priceLabels.serverNameErrorMessage"),
      });
    },
  });
};

export default useServerNameMutation;
