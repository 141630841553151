import { useParams } from "react-router-dom";

const useLinkBuilder = () => {
  const { groupId, location } = useParams();

  let baseLink = `/${groupId}`;
  baseLink += location ? `/${location}` : "";

  return (link: string) => `${baseLink}${link}`;
};

export default useLinkBuilder;
