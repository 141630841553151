import { FC } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

import { User } from "types";

interface GroupRouteProps {
  user?: User;
  loading?: boolean;
}
const GroupRoutes: FC<GroupRouteProps> = ({ user, loading }) => {
  const { groupId } = useParams<{ groupId: string }>();

  if (loading) return null;

  return user?.group.slug === groupId ? <Outlet /> : <Navigate to="/login" />;
};

export default GroupRoutes;
