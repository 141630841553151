import { FC, useMemo, useState } from "react";
import { CellSelectInputProps } from "./index";
import { Group, Loader, Select, Text } from "@mantine/core";
import styles from "./CellSelectInput.module.scss";
import { BsFillPencilFill } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const CellSelectInput: FC<CellSelectInputProps> = ({ onSelect, options, value, link }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [nextSelected, setNextSelected] = useState<string | undefined>(value);
  const [onEditMode, setOnEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);

  const tempOnSelect = (value: string | null) => {
    if (!value) return;
    setNextSelected(value);
  };

  const currentOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  const onCancelClick = () => {
    setOnEditMode(false);
    setNextSelected(value);
  };

  const onSaveClick = async () => {
    if (!nextSelected) return;
    const result = onSelect(nextSelected);
    if (result instanceof Promise) {
      setLoading(true);
      result.finally(() => {
        setLoading(false);
        setOnEditMode(false);
      });
    } else setOnEditMode(false);
  };

  const onLinkClick = () => {
    if (!link) return;
    navigate(link);
  };

  if (!currentOption) return <td />;

  return (
    <td>
      <Group>
        {onEditMode ? (
          <>
            <Select
              data={options}
              value={nextSelected}
              onChange={tempOnSelect}
              searchable
              className={styles.select}
              data-cy="cell-select"
            />
            {loading && <Loader fz="sm" />}
            <Text
              td="underline"
              c="cta.4"
              onClick={onSaveClick}
              className={styles.linkText}
              data-cy="cell-select-save">
              {t("save")}
            </Text>
            <Text
              td="underline"
              c="grey.1"
              mr="md"
              onClick={onCancelClick}
              className={styles.linkText}>
              {t("cancel")}
            </Text>
          </>
        ) : (
          <>
            <Text onClick={onLinkClick} td="underline" c="grey.1" className={styles.linkText}>
              {currentOption.label}
            </Text>
            <BsFillPencilFill
              className={styles.pencil}
              onClick={() => setOnEditMode(true)}
              data-cy="cell-select-pencil"
            />
          </>
        )}
      </Group>
    </td>
  );
};

export default CellSelectInput;
