import { FC } from "react";
import { Helmet } from "react-helmet-async";
import { Environment } from "types";

type HeadProps = {
  title?: string;
};

const Head: FC<HeadProps> = ({ title }) => {
  const currentEnv =
    import.meta.env.VITE_ENV === undefined
      ? `(${Environment.local})`
      : import.meta.env.VITE_ENV !== Environment.production
      ? `(${import.meta.env.VITE_ENV})`
      : "";

  return (
    <Helmet
      title={title ? `${currentEnv} ${title} - Valk To-Go` : undefined}
      defaultTitle={`${currentEnv} Valk To Go`}>
      <meta name="description" content="Valk To-Go back office" />
    </Helmet>
  );
};

export default Head;
