import { FC } from "react";
import { Stack, Text } from "@mantine/core";
import { useTranslation } from "react-i18next";

const NoPriceLabels: FC = () => {
  const { t } = useTranslation();
  return (
    <Stack align="center" gap="xs" mt="xl">
      <Text>{t("priceLabels.noAvailable")}</Text>
    </Stack>
  );
};

export default NoPriceLabels;
