import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";

const TransactionOverview = lazy(() => import("pages/transaction/TransactionOverview"));
const TransactionSettings = lazy(() => import("pages/transaction/settings/TransactionSettings"));
const QRCodes = lazy(() => import("pages/transaction/qrcode/QrCodeOverview"));

const UserLocations = (
  <>
    <Route index element={<Navigate to="transactions" replace />} />
    <Route path="transactions">
      <Route index element={<TransactionOverview />} />
      <Route path=":transactionId/" element={<TransactionSettings />} />
    </Route>
    <Route path="qrcodes" element={<QRCodes />} />
  </>
);

export default UserLocations;
