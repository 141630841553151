import React from "react";
import ReactDOM from "react-dom/client";
import { Environment } from "types";
import "./locale/index";
import "styles/main.scss";
import App from "./App";
import { Honeybadger, HoneybadgerErrorBoundary } from "@honeybadger-io/react";
import packageJson from "../package.json";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
const HONEYBADGER_API_KEY = import.meta.env.VITE_HONEYBADGER_API_KEY;

const getEnvironment = () => {
  if (import.meta.env.VITE_ENV === undefined) return "development";
  if (import.meta.env.VITE_ENV === Environment.production) return "production";
  return "staging"; };

if (import.meta.env.VITE_ENV === Environment.local) {
  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
} else {
  const honeybadger = Honeybadger.configure({
    apiKey: HONEYBADGER_API_KEY,
    environment: getEnvironment(),
    revision: packageJson.version,
  });
  root.render(
    <HoneybadgerErrorBoundary honeybadger={honeybadger}>
      <App />
    </HoneybadgerErrorBoundary>
  );
}
