import { FC } from "react";
import { useQuery } from "@tanstack/react-query";
import { Navigate, Outlet } from "react-router-dom";

import api from "services/api";
import { useAuthStore } from "stores/auth";
import { useGroupStore } from "stores/group";
import { ContentLoader } from "components/loader";

const PrivateRoutes: FC = () => {
  const { isAuthenticated } = useAuthStore();
  const { setCurrentGroup } = useGroupStore();

  const { isLoading, isError } = useQuery(["currentUser"], api.account.getCurrentUser, {
    enabled: !isAuthenticated,
    retry: false,
    cacheTime: 0,
    onSuccess: ({ data }) => {
      setCurrentGroup(data.group);
    },
  });

  if (isAuthenticated) return <Outlet />;

  if (isLoading) return <ContentLoader />;

  return isError ? <Navigate to="/login" /> : <Outlet />;
};

export default PrivateRoutes;
