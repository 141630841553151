const exportCsv = async (data: Blob, contentDispositionHeader: string) => {
  const url = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.download = `${contentDispositionHeader.split("filename=")[1].replace(/"/g, "")}`;
  document.body.appendChild(link);
  link.click();
};

export { exportCsv };
