import { Anchor, Group } from "@mantine/core";
import Star from "@/assets/images/Star";
import { FC } from "react";
import { Link } from "react-router-dom";
import useLinkBuilder from "@/hooks/useLinkBuilder";
import styles from "./RatingAverage.module.scss";

interface RatingAverageProps {
  data: number;
  link: string;
}
const RatingAverage: FC<RatingAverageProps> = ({ data, link }) => {
  const linkBuilder = useLinkBuilder();

  return (
    <Group gap={5}>
      <>
        <Star className={styles["star"]} />
        <Anchor
          className={styles["anchor"]}
          component={Link}
          underline="hover"
          to={linkBuilder(link)}>
          {data?.toFixed(1).toString().replace(".", ",") ?? "-"}
        </Anchor>
      </>
    </Group>
  );
};

export default RatingAverage;
