import Head from "components/head";
import Header from "components/header";
import Sidebar from "components/sidebar";
import React, { FC } from "react";
import { isBrowser } from "react-device-detect";
import styles from "./Layout.module.scss";
import { useUiStorage } from "stores/ui";

type LayoutProps = {
  pageTitle?: string;
  children: React.ReactNode;
};

const ContentLayout: FC<LayoutProps> = ({ pageTitle, children }) => {
  const { isNavFolded } = useUiStorage();
  return (
    <>
      <Head title={pageTitle} />
      <main className={styles["content-container"]}>
        <Sidebar parentStyles={styles[`sidebar${isNavFolded ? "-smaller" : ""}`]} />
        <section className={styles["header"]}>
          {isBrowser && <Header />}
          <section className={styles["body"]}>{children}</section>
        </section>
      </main>
    </>
  );
};

export { ContentLayout };
