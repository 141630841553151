import { Badge, Box, Group } from "@mantine/core";
import PropertySwitch from "components/PropertySwitch";
import { FC, useEffect, useState } from "react";
import styles from "./header.module.scss";
import { Environment } from "types";
import useCurrentUser from "utils/currentUser";

const Header: FC = () => {
  const [stagingIndicator, setStagingIndicator] = useState(false);
  const { currentUser } = useCurrentUser();

  useEffect(() => {
    setStagingIndicator(import.meta.env.VITE_ENV !== Environment.production);
  }, []);
  const environmentLabel =
    import.meta.env.VITE_ENV === undefined
      ? "local"
      : window.location.hostname.includes("develop")
      ? "develop"
      : import.meta.env.VITE_ENV === Environment.staging
      ? "staging"
      : undefined;

  return (
    <Box className={styles["header"]}>
      <Group justify="space-between">
        <Group>
          {currentUser && currentUser.allowedProperties.length > 1 && (
            <Box className={styles["property-container"]}>
              <PropertySwitch testId="navigation-property-switch" />
            </Box>
          )}
        </Group>

        {stagingIndicator && (
          <Badge color="orange" size="sm" radius="md" variant="light">
            {environmentLabel}
          </Badge>
        )}
      </Group>
    </Box>
  );
};

export default Header;
