import { Theme } from "../types";

const docStyles = document.documentElement.style;

const updatePropertyTheme = (theme: Theme) => {
  docStyles.setProperty("--primary-hotel-color", theme.primaryColor);
  docStyles.setProperty("--light-hotel-color", theme.lightColor);
  docStyles.setProperty("--dark-hotel-color", theme.darkColor);
  docStyles.setProperty("--cta-hotel-color", theme.ctaButtonColor);
  docStyles.setProperty("--search-button-color", theme.searchButtonColor);
};

export { updatePropertyTheme };
