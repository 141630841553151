import { useQuery } from "@tanstack/react-query";
import { Property, UserRoles } from "types";
import api from "services/api";
import { MINUTE_IN_MS } from "utils/consts";
import useCurrentUser from "utils/currentUser";

const usePropertyQuery = (locationId?: Property["id"]) => {
  const { currentUser } = useCurrentUser();

  return useQuery(["location", locationId ?? "admin"], () => api.property.getById(locationId!), {
    enabled: UserRoles.default !== currentUser?.role && !!locationId,
    staleTime: MINUTE_IN_MS,
    select: (data) => data.data,
  });
};

export default usePropertyQuery;
