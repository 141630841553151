import { FC, SVGProps } from "react";

const LogoTogoSmall: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="293"
      height="293"
      viewBox="0 0 293 293"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <rect width="293" height="293" fill="black" />
      <path
        d="M126.03 100.018V102.123C123.293 102.612 121.216 103.483 119.801 104.734C117.781 106.589 115.991 109.431 114.43 113.257L87.3928 178.823H85.2755L56.239 112.405C54.7454 108.998 53.6979 106.914 53.0967 106.153C52.1482 104.983 50.9457 104.041 49.5792 103.397C47.7945 102.642 45.8879 102.21 43.95 102.123V100.018H75.6191V102.123C72.0343 102.46 69.7098 103.065 68.6456 103.938C68.1363 104.333 67.7265 104.84 67.4485 105.42C67.1705 106 67.0319 106.636 67.0437 107.278C67.0437 109.174 67.9193 112.129 69.6706 116.141L89.3924 161.308L107.686 116.709C109.471 112.318 110.365 109.269 110.369 107.562C110.369 106.448 109.809 105.413 108.688 104.411C107.568 103.409 105.72 102.696 103.087 102.278C102.897 102.245 102.572 102.184 102.118 102.111V100.018H126.03Z"
        fill="white"
      />
      <path
        d="M143.225 169.559C137.856 173.69 134.484 176.076 133.11 176.718C131.049 177.665 128.804 178.149 126.534 178.138C124.862 178.191 123.198 177.89 121.652 177.253C120.107 176.617 118.716 175.66 117.572 174.447C115.231 171.986 114.06 168.748 114.06 164.732C114 162.419 114.594 160.135 115.774 158.14C117.335 155.564 120.049 153.141 123.918 150.869C127.787 148.598 134.222 145.84 143.225 142.596V140.548C143.225 135.352 142.396 131.792 140.738 129.87C139.08 127.947 136.67 126.98 133.507 126.969C131.11 126.969 129.2 127.615 127.794 128.901C127.124 129.439 126.582 130.117 126.207 130.886C125.831 131.656 125.631 132.499 125.621 133.355L125.733 136.651C125.733 138.395 125.287 139.74 124.394 140.687C123.937 141.145 123.388 141.502 122.783 141.736C122.178 141.969 121.53 142.072 120.882 142.04C120.232 142.061 119.586 141.94 118.989 141.685C118.392 141.43 117.858 141.047 117.426 140.564C116.48 139.437 116 137.994 116.082 136.528C116.082 133.273 117.762 130.282 121.123 127.554C124.484 124.826 129.189 123.462 135.238 123.462C139.887 123.462 143.698 124.237 146.67 125.789C148.936 126.978 150.699 128.933 151.644 131.3C152.335 132.934 152.678 136.274 152.674 141.322V158.992C152.674 163.954 152.77 166.994 152.96 168.111C153.151 169.225 153.464 169.976 153.901 170.338C154.32 170.705 154.861 170.904 155.419 170.895C155.979 170.906 156.532 170.77 157.021 170.499C157.816 170.006 159.359 168.623 161.648 166.352V169.531C157.379 175.213 153.302 178.054 149.415 178.054C148.569 178.089 147.727 177.934 146.951 177.599C146.175 177.265 145.485 176.76 144.934 176.122C143.836 174.857 143.266 172.669 143.225 169.559ZM143.225 165.868V146.037C137.475 148.308 133.759 149.919 132.079 150.869C129.069 152.532 126.916 154.275 125.621 156.097C124.35 157.836 123.671 159.933 123.683 162.082C123.595 164.575 124.476 167.007 126.142 168.874C126.83 169.705 127.693 170.377 128.67 170.843C129.648 171.308 130.715 171.556 131.799 171.568C134.693 171.554 138.502 169.653 143.225 165.868Z"
        fill="white"
      />
      <path
        d="M179.078 96.2714V165.305C179.078 168.564 179.316 170.722 179.79 171.78C180.232 172.807 181.007 173.657 181.991 174.196C182.982 174.753 184.831 175.02 187.536 175.02V177.063H161.872V175.02C164.269 175.02 165.91 174.775 166.784 174.28C167.725 173.715 168.45 172.855 168.845 171.836C169.338 170.7 169.584 168.523 169.584 165.305V118.028C169.584 112.16 169.452 108.553 169.187 107.206C168.922 105.858 168.494 104.93 167.904 104.422C167.269 103.919 166.475 103.656 165.664 103.681C164.361 103.754 163.084 104.063 161.894 104.594L160.919 102.607L176.502 96.2714H179.078Z"
        fill="white"
      />
      <path
        d="M204.637 96.2714V148.047L217.956 136.005C220.772 133.425 222.409 131.796 222.868 131.117C223.158 130.719 223.318 130.243 223.328 129.753C223.327 129.377 223.242 129.007 223.079 128.668C222.916 128.329 222.68 128.03 222.387 127.793C221.752 127.248 220.714 126.936 219.272 126.858V125.015H242.019V126.836C239.352 126.827 236.707 127.308 234.216 128.255C231.698 129.425 229.391 130.999 227.389 132.915L213.946 145.241L227.389 162.115C231.123 166.777 233.638 169.731 234.933 170.978C236.759 172.76 238.359 173.916 239.734 174.447C240.686 174.818 242.344 175.003 244.707 175.003V177.047H219.272V175.003C220.718 174.966 221.698 174.747 222.213 174.347C222.467 174.15 222.67 173.896 222.805 173.605C222.939 173.315 223.002 172.996 222.986 172.676C222.986 171.808 222.224 170.407 220.701 168.473L204.637 148.08V165.339C204.637 168.707 204.872 170.906 205.354 171.986C205.744 172.954 206.461 173.757 207.381 174.257C208.255 174.714 210.159 174.961 213.094 174.998V177.041H186.461V174.998C189.127 174.998 191.127 174.675 192.46 174.029C193.27 173.606 193.915 172.926 194.291 172.097C194.863 170.85 195.148 168.692 195.148 165.623V118.256C195.148 112.233 195.016 108.549 194.751 107.206C194.485 105.862 194.047 104.934 193.434 104.422C192.753 103.886 191.899 103.613 191.031 103.654C189.783 103.766 188.565 104.094 187.43 104.622L186.461 102.635L202.01 96.2714H204.637Z"
        fill="white"
      />
      <path
        d="M168.632 196.543H161.502V183.828H168.632V185.141H162.992V189.239H168.291V190.542H162.992V195.224H168.632V196.543Z"
        fill="white"
      />
      <path
        d="M179.734 196.543H178.053L174.609 190.948L171.108 196.543H169.534L173.791 189.896L169.825 183.828H171.472L174.648 188.838L177.852 183.828H179.431L175.471 189.846L179.734 196.543Z"
        fill="white"
      />
      <path
        d="M186.416 184.963C185.791 184.935 185.167 185.045 184.59 185.287C184.014 185.529 183.499 185.896 183.083 186.361C182.22 187.444 181.788 188.805 181.868 190.185C181.868 191.852 182.26 193.138 183.044 194.043C183.466 194.506 183.987 194.868 184.568 195.104C185.15 195.339 185.778 195.441 186.405 195.402C187.439 195.381 188.466 195.222 189.457 194.929V196.22C188.402 196.579 187.29 196.747 186.175 196.716C184.308 196.716 182.854 196.148 181.812 195.012C180.792 193.899 180.277 192.262 180.277 190.169C180.25 188.978 180.503 187.798 181.016 186.722C181.482 185.768 182.225 184.974 183.15 184.445C184.155 183.893 185.29 183.616 186.438 183.644C187.651 183.617 188.855 183.866 189.956 184.373L189.329 185.642C188.42 185.204 187.425 184.972 186.416 184.963Z"
        fill="white"
      />
      <path d="M192.017 196.543V183.828H193.501V195.207H199.147V196.543H192.017Z" fill="white" />
      <path
        d="M210.546 183.828V192.056C210.579 192.686 210.479 193.316 210.251 193.905C210.023 194.494 209.673 195.029 209.224 195.474C208.226 196.348 206.922 196.794 205.594 196.716C204.944 196.756 204.292 196.667 203.677 196.451C203.062 196.236 202.497 195.9 202.015 195.463C201.582 195.004 201.247 194.463 201.03 193.871C200.813 193.28 200.72 192.651 200.755 192.023V183.828H202.239V192.145C202.209 192.59 202.272 193.037 202.422 193.457C202.572 193.877 202.807 194.263 203.113 194.589C203.464 194.894 203.873 195.127 204.315 195.274C204.757 195.421 205.224 195.48 205.69 195.446C206.141 195.475 206.594 195.413 207.022 195.266C207.45 195.119 207.844 194.889 208.182 194.589C208.488 194.259 208.723 193.871 208.874 193.448C209.024 193.025 209.086 192.576 209.056 192.128V183.828H210.546Z"
        fill="white"
      />
      <path
        d="M220.902 193.158C220.925 193.661 220.826 194.162 220.612 194.619C220.399 195.075 220.077 195.474 219.676 195.78C218.702 196.45 217.531 196.78 216.349 196.716C215.155 196.782 213.961 196.592 212.848 196.159V194.706C213.402 194.937 213.978 195.112 214.567 195.229C215.171 195.357 215.787 195.422 216.405 195.424C217.192 195.48 217.977 195.285 218.645 194.867C218.889 194.688 219.085 194.451 219.216 194.178C219.347 193.905 219.409 193.605 219.396 193.303C219.411 192.926 219.32 192.552 219.132 192.223C218.902 191.894 218.594 191.627 218.236 191.444C217.626 191.121 216.989 190.851 216.332 190.636C215.369 190.352 214.493 189.834 213.783 189.128C213.255 188.492 212.982 187.685 213.016 186.862C213 186.411 213.094 185.962 213.289 185.554C213.484 185.146 213.774 184.791 214.136 184.518C214.988 183.904 216.026 183.597 217.077 183.649C218.282 183.639 219.476 183.878 220.583 184.351L220.118 185.637C219.146 185.214 218.099 184.989 217.038 184.974C216.384 184.938 215.737 185.116 215.195 185.481C214.978 185.644 214.804 185.857 214.688 186.101C214.572 186.345 214.517 186.614 214.528 186.884C214.511 187.26 214.596 187.633 214.775 187.964C214.978 188.288 215.261 188.554 215.598 188.738C216.166 189.053 216.762 189.314 217.379 189.517C218.418 189.809 219.376 190.332 220.18 191.048C220.683 191.634 220.942 192.389 220.902 193.158Z"
        fill="white"
      />
      <path d="M223.266 196.543V183.828H224.756V196.543H223.266Z" fill="white" />
      <path
        d="M235.146 196.543H228.016V183.828H235.146V185.141H229.5V189.239H234.805V190.542H229.5V195.224H235.146V196.543Z"
        fill="white"
      />
      <path
        d="M239.218 196.543H237.734V183.828H244.864V185.141H239.218V189.796H244.523V191.11H239.218V196.543Z"
        fill="white"
      />
    </svg>
  );
};

export default LogoTogoSmall;
