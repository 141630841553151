import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ComboboxItem, Select } from "@mantine/core";

import styles from "./PropertySwitch.module.scss";
import { useLocationStore } from "stores/location";
import { AllowedProperty, UserRoles } from "types";
import { useUiStorage } from "stores/ui";
import useCurrentUser from "utils/currentUser";
import i18next from "i18next";

interface PropertyProps {
  title?: string;
  selectedTitle?: string;
  testId?: string;
}

const shouldRedirect = (currentPath: string, newProperty?: AllowedProperty): boolean => {
  switch (currentPath.split("/")[0]) {
    case "qrcodes":
      return !newProperty?.validateLegalAge;
    case "voucher":
      return !newProperty;
    case "locations":
      return !!newProperty;
    case "dashboard":
      return false;
    default:
      return !!currentPath.match(/\d/);
  }
};

const PropertySwitch: FC<PropertyProps> = ({
  title = i18next.t("backToAdminSpace"),
  selectedTitle = i18next.t("adminSpace"),
  testId,
}) => {
  const { groupId } = useParams();
  const navigate = useNavigate();
  const { resetProperty } = useUiStorage();
  const { currentLocation, setCurrentLocation } = useLocationStore();
  const { currentUser, isLoading, allowedPropertiesList } = useCurrentUser();
  const adminLocation: ComboboxItem = currentLocation
    ? { value: "0", label: title }
    : { value: "0", label: selectedTitle };

  const onChange = (value: string | null) => {
    const currentPath = window.location.pathname.split(
      `/${groupId}${currentLocation ? `/${currentLocation.slug}/` : "/"}`
    )[1];

    const newProperty = currentUser?.allowedProperties.find(
      (property) => property.id === Number(value)
    );
    resetProperty();

    const subPath = shouldRedirect(currentPath, newProperty) ? "" : currentPath;

    if (newProperty === undefined) return navigate(`/${groupId}/${subPath}`);

    setCurrentLocation(newProperty);
    navigate(`/${groupId}/${newProperty?.slug}/${subPath}`);
  };

  if (isLoading) return null;
  return (
    <Select
      data-cy={testId}
      className={styles["property-switch"]}
      data={
        currentUser?.role === UserRoles.admin
          ? [adminLocation, ...allowedPropertiesList]
          : allowedPropertiesList
      }
      value={currentLocation ? String(currentLocation?.id) : "0"}
      onChange={onChange}
      readOnly={currentUser!.allowedProperties.length <= 1}
    />
  );
};

export default PropertySwitch;
