import { FC, useEffect, useMemo, useRef, useState } from "react";
import { ContentLayout } from "components/layout";
import { Trans, useTranslation } from "react-i18next";
import {
  Button,
  Group,
  Stack,
  Tabs,
  Text,
  TextInput as MTextInput,
  Title,
  Transition,
  ActionIcon,
} from "@mantine/core";
import ContentHeader from "components/contentHeader";
import Table from "components/report/Table";
import { PriceLabelTable, TableProps } from "components/report/TableTypes";
import NoPriceLabels from "./components/NoPriceLabels";
import styles from "./PriceLabelOverview.module.scss";
import { useLocationStore } from "stores/location";
import usePropertyQuery from "hooks/queries/usePropertyQuery";
import usePriceLabelQuery from "hooks/queries/usePriceLabelQuery";
import useProductsQuery from "@/hooks/queries/useProductsQuery";
import { getShopPrice } from "utils/products";
import getTranslation from "utils/getTranslation";
import { LoaderWithLayout } from "components/loader";
import useLinkBuilder from "hooks/useLinkBuilder";
import useLinkLabelsRandomlyMutation from "hooks/mutations/useLinkLabelsRandomlyMutation";
import useLabelMutation from "hooks/mutations/useLabelMutation";
import { useUiStorage } from "stores/ui";
import { UseFormReturnType, useForm } from "@mantine/form";
import { AllFormKeys, PriceLabelFormValues } from "types/formValues";
import { useMutation } from "@tanstack/react-query";
import api from "services/api";
import { useClickOutside } from "@mantine/hooks";
import { AiOutlineSearch } from "react-icons/ai";
import { HiOutlineX } from "react-icons/hi";
import debounce from "utils/debounce";
import { PriceLabel } from "types";
import TextInput from "components/template/TextInput";
import useServerNameMutation from "@/hooks/mutations/useServerNameMutation";
import usePriceLabelConfigQuery from "@/hooks/queries/usePriceLabelConfigQuery";
import { BiLinkExternal } from "react-icons/bi";
import PermissionWrapper from "@/utils/PermissionWrapper";
import PortStatus from "./components/PortStatus";

const PriceLabelOverview: FC = () => {
  const { t } = useTranslation();
  const { currentLocation } = useLocationStore();
  const { priceLabelTab, setPriceLabelTab } = useUiStorage();
  const linkBuilder = useLinkBuilder();
  const { mutate: mutateRandomLink, isLoading: isRandomlyLinking } =
    useLinkLabelsRandomlyMutation();
  const { mutate: mutateLabel } = useLabelMutation();
  const { mutate: mutateServerName } = useServerNameMutation();
  const [opened, setOpened] = useState(false);
  const [lastLabel, setLastLabel] = useState("");
  const clickOutsideRef = useClickOutside(() => setOpened(false));
  const inputRef = useRef<HTMLInputElement>(null);

  const { data: property, isLoading: isLoadingProperty } = usePropertyQuery(currentLocation!.id);
  const shopId = property?.shops[0].id;
  const {
    data: fetchedPriceLabels,
    isLoading: isLoadingLabels,
    refetch: refetchLabels,
    isFetching: isFetchingLabels,
  } = usePriceLabelQuery(shopId);
  const {
    data: products,
    isLoading: isLoadingProducts,
    refetch: refetchProducts,
    isFetching: isFetchingProducts,
  } = useProductsQuery();
  const { data: config, isLoading: isLoadingConfig } = usePriceLabelConfigQuery(shopId);

  const refetch = () => {
    refetchLabels();
    refetchProducts();
  };

  useEffect(() => {
    if (priceLabelTab === "input") inputRef.current?.focus();
  }, [inputRef.current, priceLabelTab]);

  const onLinkRandomlyClick = () => {
    if (shopId === undefined) return;
    mutateRandomLink({ shopId });
  };

  const openIpAddress = () => {
    if (config?.configUrl === undefined) return;
    window.open(config.configUrl, "_blank");
  };

  const onSetupClick = () => {
    if (shopId === undefined) return;
    mutateServerName(shopId);
  };

  const [filter, setFilter] = useState("");
  const [filteredLabels, setFilteredLabels] = useState<PriceLabel[]>([]);

  const debounceWrapper = debounce((fetchedLabels: PriceLabel[], filter: string) => {
    setFilteredLabels(
      fetchedLabels.filter((label: PriceLabel) => {
        return (
          label.productName?.toLowerCase().includes(filter.toLowerCase()) ||
          label.productId?.toString().includes(filter) ||
          label.deviceId?.toString().toLowerCase().includes(filter.toLowerCase())
        );
      }) as unknown as PriceLabel[]
    );
  }, 250);

  useEffect(() => {
    if (isLoadingLabels) return;
    if (!!fetchedPriceLabels) debounceWrapper(fetchedPriceLabels, filter);
    else setFilteredLabels([]);
  }, [filter, fetchedPriceLabels, isLoadingLabels]);

  const tableRows: PriceLabelTable[] = useMemo(() => {
    if (!filteredLabels || !products) return [];
    const options = products.map((product) => ({
      value: String(product.id),
      label: getTranslation(product.translations)?.name ?? "",
    }));

    return filteredLabels.map((label) => {
      const product = products.find((product) => product.id === label.productId);
      const link = linkBuilder(
        `/categories/${product?.categoryIds[0].categoryId}/products/edit/${product?.id}`
      );
      return {
        ["priceLabels.label"]: label.deviceId,
        ["priceLabels.connectionStatus"]: label.state,
        ["priceLabels.product"]: {
          value: String(product?.id ?? ""),
          options,
          onSelect: (value) =>
            new Promise((resolve) => {
              const product = products.find((product) => product.id === Number(value));
              if (!product || !currentLocation || shopId === undefined) return;
              mutateLabel(
                {
                  product: { id: product.id, eanCode: product.eanCode },
                  labelId: label.deviceId,
                  shopId,
                },
                {
                  onSettled: () => resolve(),
                }
              );
            }),
          link,
        },
        ["priceLabels.shopPrice"]: product ? getShopPrice(product) : undefined,
        ["priceLabels.batteryLevel"]: label.batteryLevel ? `${label.batteryLevel}%` : undefined,
      };
    });
  }, [filteredLabels, products]);

  const tableData: TableProps<PriceLabelTable> = {
    showAll: true,
    headings: {
      ["priceLabels.label"]: {
        type: "text",
      },
      ["priceLabels.connectionStatus"]: {
        type: "connectionStatus",
      },
      ["priceLabels.product"]: {
        type: "selectInput",
      },
      ["priceLabels.batteryLevel"]: {
        type: "text",
        align: "center",
      },
      ["priceLabels.shopPrice"]: {
        type: "money",
        align: "center",
      },
    },
    data: tableRows,
  };

  const amountOfUnlinkedProducts = useMemo(() => {
    if (!fetchedPriceLabels || !products) return 0;
    const publishedProducts = products.filter((product) => product.state === "published");
    const linkedProductIds = Array.from(
      new Set(fetchedPriceLabels.map((label) => label.productId))
    ).filter((id) => id !== null);
    return publishedProducts.length - linkedProductIds.length;
  }, [fetchedPriceLabels, products]);

  const form: UseFormReturnType<PriceLabelFormValues> = useForm({
    initialValues: {
      labelId: "",
      shopId: shopId!,
    },
  });

  const disabled = form.values.labelId.length !== 16;

  const addLabelMutation = useMutation(
    (data: PriceLabelFormValues) => api.property.addPriceLabel(currentLocation!.id, data),
    {
      onSuccess: () => {
        setOpened(true);
        form.reset();
      },
      onError: () => {
        form.reset();
      },
    }
  );

  const handleSubmit = (values: PriceLabelFormValues) => {
    if (values.labelId.length !== 16 || values.labelId !== values.labelId.toUpperCase()) {
      return form.setFieldError("labelId", t("form.invalidLabel"));
    }

    addLabelMutation.mutate({
      labelId: values.labelId,
      shopId: Number(shopId),
    });

    setLastLabel(values.labelId);
  };

  if (isLoadingProperty || isLoadingLabels || isLoadingProducts || isLoadingConfig)
    return <LoaderWithLayout />;

  return (
    <ContentLayout pageTitle={t("menu.priceLabels")}>
      <ContentHeader horizontal>
        <Group justify="apart" align="flex-start" grow className={styles.header}>
          <Stack>
            <Title>{t("menu.priceLabels")}</Title>
            <Text>
              <Trans i18nKey="priceLabels.labelCount" count={fetchedPriceLabels?.length ?? 0}>
                <b />
              </Trans>
              &nbsp;&#8226;&nbsp;
              <Trans i18nKey="priceLabels.notLinkedCount" count={amountOfUnlinkedProducts}>
                <b />
              </Trans>
            </Text>

            <MTextInput
              className={styles["search-input"]}
              placeholder={t("search")}
              leftSection={<AiOutlineSearch />}
              type="search"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
              rightSection={
                <HiOutlineX onClick={() => setFilter("")} className={styles["cancel-icon"]} />
              }
            />
          </Stack>
          <Group justify="right" mt="xs">
            <Button
              color="cta.4"
              variant="outline"
              onClick={refetch}
              loading={isFetchingLabels || isFetchingProducts}
              data-cy="refetch-price-labels">
              {t("priceLabels.fetchLabels")}
            </Button>
            <Button
              color="cta.4"
              onClick={onLinkRandomlyClick}
              loading={isRandomlyLinking}
              data-cy="link-products">
              {t("priceLabels.linkProducts")}
            </Button>
          </Group>
        </Group>
      </ContentHeader>

      <Tabs variant="outline" value={priceLabelTab} onChange={setPriceLabelTab}>
        <Tabs.List>
          <Tabs.Tab value="table">{t("priceLabels.tableTab")}</Tabs.Tab>
          <Tabs.Tab value="input">{t("priceLabels.inputTab")}</Tabs.Tab>
          <PermissionWrapper permission="priceLabelConfig" action="read">
            <Tabs.Tab value="setup">{t("priceLabels.setupTab")}</Tabs.Tab>
          </PermissionWrapper>
        </Tabs.List>
        <Tabs.Panel value="table" pt="xl">
          <Table {...tableData} NoDataComponent={<NoPriceLabels />} />
        </Tabs.Panel>
        <Tabs.Panel value="input" mt="xl">
          <Transition mounted={opened} transition="fade" duration={400} timingFunction="ease">
            {() => (
              <Text ref={clickOutsideRef} fz="sm" c="darkgreen">
                {t("priceLabels.saved", {
                  label: lastLabel,
                })}
              </Text>
            )}
          </Transition>
          <Group mb="sm" className={styles.labelInput}>
            <form onSubmit={form.onSubmit(handleSubmit)}>
              <TextInput
                name="labelId"
                form={form as UseFormReturnType<AllFormKeys>}
                translationString="priceLabels.idInput"
                testId="page-price-label-id-input"
                data-autofocusx
                required
                inputRef={inputRef}
              />

              <Button
                color="cta.4"
                type="submit"
                disabled={disabled}
                data-cy="page-price-label-id-save-button">
                {t("priceLabels.save")}
              </Button>
            </form>
          </Group>
        </Tabs.Panel>
        <PermissionWrapper permission="priceLabelConfig" action="read">
          <Tabs.Panel value="setup" mt="xl">
            <Text mb="sm">
              {t("priceLabels.ipAddress")}: {config?.hostIpAddress}
            </Text>
            <Text mb="sm">
              <Group gap="xs">
                {t("priceLabels.port")}: {config?.hostPort}
                <PortStatus online={config?.hostPortStatus === "online"} />
              </Group>
            </Text>
            <Text mb="sm">
              <Group gap="xs">
                {t("priceLabels.configPort")}: {config?.hostConfigPort}
                <PortStatus online={config?.hostConfigPortStatus === "online"} />
                {config?.configUrl && (
                  <ActionIcon onClick={openIpAddress} variant="transparent" c="grey.3">
                    <BiLinkExternal size={18} />
                  </ActionIcon>
                )}
              </Group>
            </Text>
            <Text mb="sm">
              {t("priceLabels.serverName")}: {config?.serverName}
            </Text>
            <Button color="cta.4" variant="outline" onClick={onSetupClick}>
              {t("priceLabels.setupButton")}
            </Button>
          </Tabs.Panel>
        </PermissionWrapper>
      </Tabs>
    </ContentLayout>
  );
};

export default PriceLabelOverview;
