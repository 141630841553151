import { createTheme } from "@mantine/core";

const theme = createTheme({
  components: {
    Anchor: {
      styles: (theme: {
        colors: {
          brand: any[];
        };
      }) => ({
        root: {
          color: theme.colors.brand[1],
        },
      }),
    },
  },
  fontSizes: {
    xs: "0.75rem",
    sm: "1rem",
    md: "1.125rem",
    lg: "1.25rem",
    xl: "1.5rem",
  },
  colors: {
    cta: [
      "#FFEEDF",
      "#F8D7BF",
      "#F3BD96",
      "#EEA36D",
      "#E8833A",
      "#E46F1B",
      "#B75915",
      "#894310",
      "#5B2D0B",
      "#2E1605",
    ],
    grey: [
      "#292929",
      "#3D3D3D",
      "#666666",
      "#999999",
      "#C2C2C2",
      "#E0E0E0",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
    ],
    brand: [
      "#283547",
      "#292723",
      "#F1F3F5",
      "#D6D6D6",
      "#444E5C",
      "#434343",
      "#556070",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
    ],
    location: [
      "#947EC5",
      " #545454",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
      "#EFEFEF",
    ],
  },
});

export default theme;
