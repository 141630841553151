import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { Environment } from "types";

const firebaseConfig =
  import.meta.env.VITE_ENV === Environment.production
    ? {
        apiKey: "AIzaSyBlN0jz2Kef63UdPTguyBE8CcvugAdeiQc",
        authDomain: "valk-togo-backoffice.firebaseapp.com",
        projectId: "valk-togo-backoffice",
        storageBucket: "valk-togo-backoffice.appspot.com",
        messagingSenderId: "850171123922",
        appId: "1:850171123922:web:1233cc9b158076958a350b",
      }
    : {
        apiKey: "AIzaSyDPaAlBHYYFJ1dez5DJfuVBtKxWOpkGTFI",
        authDomain: "valk-togo-backoffice---staging.firebaseapp.com",
        projectId: "valk-togo-backoffice---staging",
        storageBucket: "valk-togo-backoffice---staging.appspot.com",
        messagingSenderId: "175226397020",
        appId: "1:175226397020:web:8ef97d868ceab8f3416199",
      };

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const logFirebaseEvent = (message: string, params = {}) => logEvent(analytics, message, params);

export { logFirebaseEvent };
