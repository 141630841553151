import { FC } from "react";
import { Navigate, Outlet, useParams } from "react-router-dom";

import { useGroupStore } from "stores/group";
import { useLocationStore } from "stores/location";
import { AllowedProperty, User } from "types";

interface LocationRouteProps {
  user?: User;
  loading?: boolean;
}

const LocationRoutes: FC<LocationRouteProps> = ({ user, loading }) => {
  const { location } = useParams<{ location: string }>();
  const { currentGroup } = useGroupStore();
  const { currentLocation, setCurrentLocation } = useLocationStore();
  let allowedProperty: AllowedProperty | undefined = undefined;

  if (loading) return null;
  if (!user?.allowedProperties.length) return <Navigate to="/login" />;
  if (location) {
    allowedProperty = user?.allowedProperties?.find((property) => property.slug === location);
  }

  if (!allowedProperty)
    return <Navigate to={`/${currentGroup?.slug}/${user?.allowedProperties[0]?.slug}`} />;

  if (allowedProperty !== currentLocation) {
    setCurrentLocation(allowedProperty);
  }

  return <Outlet />;
};

export default LocationRoutes;
