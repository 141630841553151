import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { Dispatch, SetStateAction } from "react";

dayjs.locale("nl");
dayjs.extend(localeData);

const dateRangeToString = (dates: Date[]) => {
  if (dates.length === 0) return null;
  const startDateString = `${dates[0].getDate()} ${dayjs.months()[dates[0].getMonth()]}`;
  if (dates.length === 1) return startDateString;
  const endDate = dates[dates.length - 1];
  const endDateString = `${endDate.getDate()} ${dayjs.months()[endDate.getMonth()]}`;
  if (startDateString === endDateString) return startDateString;
  return `${startDateString} - ${endDateString}`;
};

const utcToLocalDateTimeString = (dateString?: string | null): string => {
  return !!dateString && !isNaN(new Date(dateString).getTime())
    ? dayjs(new Date(dateString)).format("D MMM YY - HH:mm")
    : "-";
};

const utcToLocalDateString = (dateString: string | null): string => {
  return !!dateString ? dayjs(new Date(dateString)).format("D MMM YYYY") : "-";
};

const formatUtcDateWithTimeZone = (date: Date): string => {
  const dateToFormat = dayjs(date);
  return dateToFormat.format("YYYY-MM-DD[T]HH:mm:ssZ");
};

const getSelectionType = (
  dates: Date[],
  isPeriodSelection: boolean,
  selectedType?: Dispatch<SetStateAction<"day" | "month" | "week">>
) => {
  if (dates[0] instanceof Date && dates[1] instanceof Date) {
    let diff = dayjs(dates[1]).diff(dayjs(dates[0]), "day");
    isPeriodSelection && (diff += 1);
    if (diff <= 1) {
      if (!selectedType) return "day";
      selectedType("day");
    } else if (diff === 7) {
      if (!selectedType) return "week";
      selectedType("week");
    } else if (dayjs(dates[0]).daysInMonth() === diff) {
      if (!selectedType) return "month";
      selectedType("month");
    }
  }
};

export {
  dateRangeToString,
  utcToLocalDateTimeString,
  utcToLocalDateString,
  formatUtcDateWithTimeZone,
  getSelectionType,
};
