import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import BaseLocations from "./BaseLocations";
import PriceLabelOverview from "pages/price-labels/PriceLabelOverview";

const ShopSettings = lazy(() => import("pages/shop/settings/ShopSettings"));
const TurnoverOverview = lazy(() => import("pages/administration/turnover/AdministrationTurnover"));
const QRCodes = lazy(() => import("pages/transaction/qrcode/QrCodeOverview"));
const VoucherReport = lazy(() => import("pages/voucher/report/VoucherReportOverview"));

const PropertyLocations = (
  <>
    <Route index element={<Navigate to="dashboard" />} />
    <Route path="qrcodes" element={<QRCodes />} />
    <Route path="shop">
      <Route path="create" element={<ShopSettings />} />
      <Route path=":shopId" element={<ShopSettings />} />
    </Route>
    <Route path="administration/turnover" element={<TurnoverOverview />} />
    <Route path="voucher">
      <Route path="dashboard" element={<VoucherReport />} />
    </Route>
    <Route path="price-labels" element={<PriceLabelOverview />} />
    {BaseLocations}
  </>
);
export default PropertyLocations;
