import { FC } from "react";
import classNames from "classnames";
import styles from "./Sidebar.module.scss";
import { useLocationStore } from "stores/location";
import MobileNavigation from "./components/MobileNavigation";
import SideNavigation from "./components/SideNavigation";
import { BrowserView, MobileOnlyView, TabletView } from "react-device-detect";

type SidebarProps = {
  parentStyles: string;
};

const Sidebar: FC<SidebarProps> = ({ parentStyles }) => {
  const { currentLocation } = useLocationStore();
  const sidebarClasses = classNames(styles.sidebar, parentStyles, {
    [styles["sidebar-location"]]: !!currentLocation,
  });
  return (
    <>
      <MobileOnlyView>
        <MobileNavigation sidebarClasses={sidebarClasses} />
      </MobileOnlyView>
      <BrowserView>
        <SideNavigation sidebarClasses={sidebarClasses} />
      </BrowserView>
      <TabletView>
        <SideNavigation sidebarClasses={sidebarClasses} />
      </TabletView>
    </>
  );
};

export default Sidebar;
