import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "services/api";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { NotificationColors } from "types";

const useLinkLabelsRandomlyMutation = () => {
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  return useMutation(api.priceLabels.assignRandomly, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["priceLabels", variables.shopId]);
      showNotification({
        color: NotificationColors.success,
        title: t("priceLabels.linked"),
        message: t("priceLabels.linkedMessage"),
      });
    },
    onError: () => {
      showNotification({
        color: NotificationColors.error,
        title: t("priceLabels.linkedError"),
        message: t("priceLabels.linkedErrorMessage"),
      });
    },
  });
};

export default useLinkLabelsRandomlyMutation;
