import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "services/api";
import { useAuthStore } from "stores/auth";
import { NotificationColors } from "types";

const useLogout = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuthStore();

  return async () => {
    try {
      await api.users.logout();
      setIsAuthenticated(false);
      sessionStorage.clear();
      navigate("/login");
    } catch (err) {
      showNotification({
        title: t("auth.logoutErrorTitle"),
        message: t("auth.logoutErrorMessage"),
        c: NotificationColors.error,
      });
    }
  };
};

export default useLogout;
