import { FC } from "react";
import TableRow from "./TableRow";
import { TableHeadings, TableOptions } from "./TableTypes";
import { ReportPropertyTotals } from "types";

interface TableBodyProps {
  testId?: string;
  headings: TableHeadings<TableOptions>;
  data: TableOptions[];
  type?: string;
  styling?: "report";
}

const TableBody: FC<TableBodyProps> = ({ testId, headings, data, type, styling }) => {
  {
    return (
      <>
        {data.map((rowData: TableOptions, rowIndex: number) => {
          if (type === "turnover" && "report.shop" in rowData) {
            return rowData["report.shop"].map(
              (shop: Omit<ReportPropertyTotals, "shops">, shopIndex: number) => (
                <TableRow
                  testId={testId}
                  key={`${shop.name}${shop.id}`}
                  headings={headings}
                  rowData={rowData}
                  rowIndex={rowIndex}
                  shop={shop}
                  shopIndex={shopIndex}
                  styling={styling}
                />
              )
            );
          }

          return (
            <TableRow
              testId={testId}
              key={rowIndex}
              headings={headings}
              rowData={rowData}
              rowIndex={rowIndex}
              styling={styling}
            />
          );
        })}
      </>
    );
  }
};

export default TableBody;
