import { Category, LedgerAccount, Product, ProductOverview } from "types";

const getLedgerAccountId = (
  product: Product | ProductOverview,
  locationId?: number
): number | undefined => {
  const locationConfig = product?.propertyConfigs?.find(
    (config) => config.propertyId === locationId
  );
  return locationConfig?.ledgerAccountId
    ? locationConfig.ledgerAccountId
    : product.ledgerAccountId
    ? product.ledgerAccountId
    : undefined;
};

const formatDescription = (ledger?: LedgerAccount) => {
  if (!ledger) return "-";
  return ledger?.propertyId === null
    ? ledger?.description
    : `${ledger.description} - ${ledger?.property?.extId}`;
};

const validateProductVisibility = (
  products: Product[],
  category: Category,
  locationId: number,
  shopId: number
) => {
  const productsInCategory = products.filter((product) => category.productIds.includes(product.id));
  return productsInCategory?.some((product) => {
    if (product.archivedAt !== null) return false;
    const locationConfig = product?.propertyConfigs.find(
      (config) => config.propertyId === locationId
    );
    if (!locationConfig) return true;
    const shopConfig = locationConfig?.shopConfigs.find(
      (shopConfig) => shopConfig.shopId === shopId
    );
    if (!shopConfig) return true;
    return shopConfig?.visible;
  });
};

export const getShopPrice = (product: Pick<Product, "propertyConfigs" | "retailPriceCents">) => {
  const customPrice = product?.propertyConfigs?.[0]?.customPriceCents;
  return customPrice === undefined || customPrice === null ? product.retailPriceCents : customPrice;
};

export { getLedgerAccountId, validateProductVisibility, formatDescription };
