import { HelmetProvider } from "react-helmet-async";
import { MantineProvider } from "@mantine/core";
import { ModalsProvider } from "@mantine/modals";
import { Notifications } from "@mantine/notifications";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

import * as dayjs from "dayjs";
import "dayjs/locale/nl.js";
import mantineTheme from "./styles/mantine-theme";
import AppRoutes from "routes";
import { LoaderWithLayout, LoaderWithoutLayout } from "components/loader";
import { Suspense } from "react";
import { BrowserRouter } from "react-router-dom";
import "./firebase";
import "@mantine/core/styles.css";
import "@mantine/dates/styles.css";
import "@mantine/notifications/styles.css";

dayjs.locale("nl");

/*
Enable codesplitting by lazy loading the different pages.
Using this technique, pages that are not required on the home page can be split out into separate bundles, thereby decreasing load time on the initial page and improving performance.
*/
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <MantineProvider withCssVariables theme={mantineTheme}>
      <Notifications position="top-right" />
      <ModalsProvider>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <BrowserRouter>
              <Suspense
                fallback={
                  window.location.pathname === "/login" ? (
                    <LoaderWithoutLayout />
                  ) : (
                    <LoaderWithLayout />
                  )
                }>
                <AppRoutes />
              </Suspense>
            </BrowserRouter>
          </HelmetProvider>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </ModalsProvider>
    </MantineProvider>
  );
}

export default App;
