import { Box, Space, Table as MTable, Text } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./Report.module.scss";
import TableBody from "./TableBody";
import TableHeading from "./TableHeading";
import { sortReportData, sortTurnoverReportData } from "./profiles/Sorting";
import { TableOptions, TableProps } from "./TableTypes";
import i18next from "i18next";
import TableFooter from "./TableFooter";

const Table: FC<TableProps<TableOptions>> = ({
  testId,
  showAll = false,
  hasShowAllOption,
  type,
  title,
  helper,
  sortHeading,
  styling,
  headings,
  data,
  subFooter,
  footerData,
  footer,
  NoDataComponent,
}) => {
  const { t } = useTranslation();

  const [sortedData, setSortedData] = useState<TableOptions[]>(data);
  const [sortingIndex, setSortingIndex] = useState<string | undefined>(sortHeading ?? "");
  const [showAllRows, setShowAllRows] = useState<boolean>(showAll);

  const onHeadingClick = (index: string, reversed: boolean) => {
    const unsortedData = [...data];
    const objectType = headings[index].type;

    const newSorted: TableOptions[] =
      type === "turnover"
        ? sortTurnoverReportData(unsortedData, objectType, reversed)
        : sortReportData(unsortedData, index, objectType);
    setSortedData(reversed ? newSorted.reverse() : newSorted);
  };

  const handleSorting = (reversed: boolean, index?: string) => {
    if (!index) return;
    setSortingIndex(index);
    onHeadingClick(index, reversed);
  };

  const visibleRows = showAllRows ? sortedData : sortedData.slice(0, 5);
  const visibleFooterRows = showAllRows ? footerData : footerData?.slice(0, 5);

  useEffect(() => {
    data.length === 0 || !sortingIndex ? setSortedData(data) : handleSorting(true, sortingIndex);
  }, [data]);

  return (
    <>
      {title && (
        <Text fz="xl" fw={700}>
          {title}
        </Text>
      )}
      {helper && (
        <Text fz="sm" c="gray">
          {helper}
        </Text>
      )}
      <Box className={styles["table-container"]}>
        <MTable className={styles["table"]} captionSide="bottom" data-cy={testId} striped>
          {!!data && data.length === 0 && !NoDataComponent && (
            <MTable.Caption>{t("nothingFound")}</MTable.Caption>
          )}
          <MTable.Thead>
            <MTable.Tr className={styles[`table-header${styling ? `-${styling}` : ""}`]}>
              {Object.entries(headings).map(([key, value]) => {
                return (
                  <MTable.Th key={key}>
                    {!!value.canSort ? (
                      <TableHeading
                        testId={testId}
                        heading={key}
                        alignment={value.align}
                        sortingIndex={sortingIndex}
                        handleSorting={handleSorting}
                        countOfLines={sortedData.length}
                      />
                    ) : (
                      <Text fw={700} fz="sm" c="gray.7" mr="md" ta={value.align}>
                        {i18next.getResource(i18next.language, "translation", key) ?? key}
                      </Text>
                    )}
                  </MTable.Th>
                );
              })}
            </MTable.Tr>
          </MTable.Thead>
          <MTable.Tbody data-cy={`${testId}-body`}>
            <TableBody
              testId={testId}
              data={visibleRows}
              headings={headings}
              type={type}
              styling={styling}
            />
          </MTable.Tbody>
          {subFooter && !!data && data.length > 0 && (
            <TableFooter footer={subFooter} headings={Object.keys(headings)} />
          )}
          {visibleFooterRows && !!data && data.length > 0 && (
            <MTable.Tbody data-cy={`${testId}-body`}>
              <TableBody
                testId={testId}
                data={visibleFooterRows}
                headings={headings}
                type={type}
                styling={styling}
              />
            </MTable.Tbody>
          )}
          {footer && !!data && data.length > 0 && (
            <TableFooter footer={footer} headings={Object.keys(headings)} />
          )}
        </MTable>
        {!!data && data.length === 0 && NoDataComponent}
      </Box>
      {data.length > 5 && title && !hasShowAllOption && (
        <Text
          mt="xl"
          ta="center"
          fz="sm"
          td="underline"
          fw={500}
          c="gray"
          className={styles["more"]}
          onClick={() => setShowAllRows((prev) => !prev)}
          data-cy={testId && testId.concat("-show-more")}>
          {showAllRows
            ? t("report.viewLess", { item: title.toLowerCase() })
            : t("report.viewMore", { item: title.toLowerCase() })}
        </Text>
      )}
      <Space p="sm" />
    </>
  );
};

export default Table;
