import i18next from "i18next";
import { Language, Translation } from "types";

const getTranslation = (translations: Translation[], language?: Language) => {
  const lang = language ?? Language.nl;

  const transaction = translations.find((t) => t.language === lang);

  if (transaction) return transaction;
  return translations.find((t) => t.language === Language.nl);
};

const getTranslationObject = (translationString: string, language?: Language) => {
  const lang = language ?? Language.nl;
  return i18next.getResource(lang, "translation", translationString);
};
export default getTranslation;
export { getTranslationObject };
