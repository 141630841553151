import { lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import BaseLocations from "./BaseLocations";

const LocationOverview = lazy(() => import("pages/location/LocationOverview"));

const LocationSettings = lazy(() => import("pages/location/settings/LocationSettings"));

const AdminLocations = (
  <>
    <Route index element={<Navigate to="dashboard" />} />
    <Route path="locations">
      <Route index element={<LocationOverview />} />
      <Route path="create" element={<LocationSettings />} />
    </Route>
    {BaseLocations}
  </>
);

export default AdminLocations;
