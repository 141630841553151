import api from "@/services/api";
import { Shop } from "@/types";
import { useQuery } from "@tanstack/react-query";

const usePriceLabelConfigQuery = (shopId?: Shop["id"]) => {
  return useQuery(["config", shopId], () => api.priceLabels.config.get(shopId!), {
    enabled: shopId !== undefined,
    select: (data) => data.data,
  });
};

export default usePriceLabelConfigQuery;
