import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "services/api";
import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { NotificationColors } from "types";

const useLabelMutation = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation(api.priceLabels.update, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(["priceLabels", variables.shopId]);
      showNotification({
        color: NotificationColors.success,
        title: t("priceLabels.updated"),
        message: t("priceLabels.updatedMessage", {
          name: variables.labelId,
        }),
      });
    },
    onError: () => {
      showNotification({
        color: NotificationColors.error,
        title: t("priceLabels.updatedError"),
        message: t("priceLabels.updatedErrorMessage"),
      });
    },
  });
};

export default useLabelMutation;
