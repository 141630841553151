import { Anchor, Group, Table, Text } from "@mantine/core";
import useLinkBuilder from "hooks/useLinkBuilder";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { BsExclamationOctagonFill } from "react-icons/bs";
import { FiAlertOctagon } from "react-icons/fi";
import { HiCheck, HiX, HiOutlineTrash } from "react-icons/hi";
import { Link } from "react-router-dom";
import { ReportPropertyTotals } from "types";
import formatMoney from "utils/formatMoney";
import getRowData from "utils/getRowData";
import getTranslation from "utils/getTranslation";
import profiles from ".";
import styles from "../Report.module.scss";
import { TableOptions } from "../TableTypes";
import Badge from "components/badge";
import CellSelectInput from "./CellSelectInput";
import Star from "assets/images/Star";
import RatingAverage from "@/pages/report/components/RatingAverage";

interface TableCellProps {
  testId?: string;
  profile?: profiles;
  propName: string;
  rowData?: TableOptions;
  extraData?: {
    shop?: Omit<ReportPropertyTotals, "shops">;
    shopIndex?: number;
  };
  align?: "left" | "center" | "right";
  alignContent?: "left" | "space-between";
  footerData?: number | string;
  footer?: boolean;
}

const TableCell: FC<TableCellProps> = ({
  testId,
  profile,
  propName,
  rowData,
  extraData,
  align,
  alignContent = "space-between",
  footerData,
  footer = false,
}) => {
  const { t } = useTranslation();
  const linkBuilder = useLinkBuilder();

  const data = footer ? footerData : getRowData(rowData!, propName);
  switch (profile) {
    case "translation":
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          {getTranslation(data)?.name ?? t("report.errorLoadingContent")}
        </Table.Td>
      );
    case "textSubTranslation":
      return (
        <Table.Td key={propName} align={align} style={{ whiteSpace: "nowrap" }} data-cy={testId}>
          <Text>{data?.text ?? data ?? "-"}</Text>
          <Text fz="xs" fw={500} c="darkgrey">
            {data?.subtext ?? undefined}
          </Text>
        </Table.Td>
      );
    case "translationLinkLabel": {
      const { concept, ownProduct, location } = data?.labels ?? {};
      if (concept && testId) {
        testId = testId.concat("-concept");
      }
      if (location && testId) {
        testId = testId.concat("-location");
      }

      const productLabels = (
        <Group justify="right">
          {concept && <Badge type="concept" ml={10} />}
          {ownProduct && <Badge type="ownProduct" ml={10} />}
          {typeof location === "string" && <Badge type="location" title={location} ml={10} />}
        </Group>
      );

      return (
        <Table.Td key={propName} align={align}>
          <Group justify={alignContent} wrap="nowrap">
            {!!data.link ? (
              <Anchor
                fz="sm"
                component={Link}
                underline="always"
                style={{ whiteSpace: "nowrap" }}
                data-cy={testId}
                to={linkBuilder(data.link)}>
                {getTranslation(data.translations)?.name ?? t("report.errorLoadingContent")}
              </Anchor>
            ) : (
              <Text style={{ whiteSpace: "nowrap" }} data-cy={testId}>
                {getTranslation(data.translations)?.name ?? t("report.errorLoadingContent")}
              </Text>
            )}
            {data?.labels && productLabels}
          </Group>
        </Table.Td>
      );
    }

    case "translationBadge": {
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          <Badge type="location" title={data.location} />
        </Table.Td>
      );
    }

    case "money":
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          {data === -1 ? (
            <span>-</span>
          ) : (
            <span>
              {formatMoney(
                extraData?.shop
                  ? getRowData(extraData?.shop, "revenueInclVat") / 100
                  : data === undefined
                    ? undefined
                    : data / 100
              )}
            </span>
          )}
        </Table.Td>
      );

    case "moneyWarning":
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          {isNaN(data.amount) || data.amount === "" ? (
            <span>-</span>
          ) : (
            <span>
              {formatMoney(data.amount)}
              {data.priceNote && (
                <FiAlertOctagon style={{ marginLeft: "0.5rem" }} size={16} color="orange" />
              )}
            </span>
          )}
        </Table.Td>
      );

    case "price": {
      const isPriceDiff = data.price !== data.retail && data.propId !== null;

      const priceAdvice = () => (
        <Group ml="sm" className={styles["price-match"]}>
          <FiAlertOctagon color="ffa500" />
          <Text className={styles["retail-price"]} data-cy={testId}>
            {`${t("priceAdvice")}:
          ${formatMoney(data.retail / 100)}`}
          </Text>
        </Group>
      );

      return (
        <Table.Td key={propName} align={align}>
          <Group className={styles["money-format"]}>
            <Text className={styles["shop-price"]} data-cy={testId}>
              {formatMoney(data.price / 100)}
            </Text>
            {isPriceDiff && priceAdvice()}
          </Group>
        </Table.Td>
      );
    }

    case "link": {
      if (extraData?.shopIndex && extraData.shopIndex !== 0)
        return (
          <Table.Td key={propName} data-cy={testId}>
            <Text style={{ minHeight: "2.1rem" }}></Text>
          </Table.Td>
        );
      return (
        <Table.Td key={propName} align={align}>
          <Anchor
            fz="sm"
            data-cy={testId}
            underline="always"
            style={{ whiteSpace: "nowrap" }}
            component={Link}
            to={linkBuilder(data.link)}>
            {data.name}
          </Anchor>
        </Table.Td>
      );
    }

    case "check":
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          {data && (
            <span>
              <HiCheck />
            </span>
          )}
        </Table.Td>
      );

    case "score":
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          {data && [...Array(data)].map((_, index) => <Star key={index} />)}
        </Table.Td>
      );

    case "connectionStatus":
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          {data == "connected" && <HiCheck size={24} />}
          {data == "disconnected" && <HiX size={24} color="red" />}
          {data == "removed" && <HiOutlineTrash size={24} color="red" />}
        </Table.Td>
      );

    case "averageScore":
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          <RatingAverage data={data.average} link={data.link} />
        </Table.Td>
      );

    case "checkWarning":
      return (
        <Table.Td key={propName} align={align} data-cy={testId}>
          <span>
            {data ? <HiCheck /> : <BsExclamationOctagonFill className={styles["warning-icon"]} />}
          </span>
        </Table.Td>
      );

    case "number":
    case "text":
      if (extraData?.shop)
        return (
          <Table.Td key={propName} align={align} data-cy={testId}>
            {extraData?.shop.name}
          </Table.Td>
        );
      return (
        <Table.Td key={propName} align={align} style={{ whiteSpace: "nowrap" }} data-cy={testId}>
          {data ?? "-"}
        </Table.Td>
      );

    case "selectInput":
      return <CellSelectInput {...data} />;

    default:
      return <Table.Td key={propName} data-cy={testId} />;
  }
};

export default TableCell;
