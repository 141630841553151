import getRowData from "utils/getRowData";
import getTranslation from "utils/getTranslation";
import { TableOptions, TurnoverReportTable } from "../TableTypes";

const handleShopSorting = (reversed: boolean, data: TurnoverReportTable[]) => {
  const sortedByShopInProperty: TurnoverReportTable[] = data.map((property) => {
    const sortedShops = property["report.shop"].sort((a, b) => a.name.localeCompare(b.name));
    return {
      ...property,
      ["report.shop"]: reversed ? sortedShops : sortedShops.reverse(),
    };
  });

  return sortedByShopInProperty.sort((a, b) =>
    b["report.shop"][0].name.localeCompare(a["report.shop"][0].name)
  );
};

const handleTurnoverSorting = (reversed: boolean, data: TurnoverReportTable[]) => {
  const sortedByShopTurnover: TurnoverReportTable[] = data.map((property) => {
    const sortedShops = property["report.shop"].sort((a, b) => a.revenueInclVat - b.revenueInclVat);
    return {
      ...property,
      ["report.shop"]: reversed ? sortedShops.reverse() : sortedShops,
    };
  });

  return sortedByShopTurnover.sort((a, b) => a["report.turnover"] - b["report.turnover"]).flat();
};

const sortTurnoverReportData = (
  unsortedData: TableOptions[],
  profile?: string,
  reversed = false
) => {
  switch (profile) {
    case "text":
      return handleShopSorting(reversed, unsortedData as TurnoverReportTable[]);
    case "money":
      return handleTurnoverSorting(reversed, unsortedData as TurnoverReportTable[]);
    default:
      return unsortedData;
  }
};

const sortReportData = (unsortedData: TableOptions[], propName: string, profile?: string) => {
  return unsortedData.sort((a, b) => {
    switch (profile) {
      case "translationLinkLabel": {
        const aTranslation = getTranslation(getRowData(a, propName).translations)?.name ?? "#";
        const bTranslation = getTranslation(getRowData(b, propName).translations)?.name ?? "#";

        return bTranslation.localeCompare(aTranslation);
      }
      case "translation": {
        const aTranslation = getTranslation(getRowData(a, propName))?.name ?? "#";
        const bTranslation = getTranslation(getRowData(a, propName))?.name ?? "#";
        return aTranslation.localeCompare(bTranslation);
      }
      case "money":
      case "number":
      case "price":
        return (getRowData(a, propName) ?? 0) - (getRowData(b, propName) ?? 0);
      case "link":
      case "text":
        return (getRowData(a, propName) ?? "#").localeCompare(getRowData(b, propName) ?? "#");
      case "check":
      case "checkWarning":
        return (getRowData(b, propName) ? 1 : 0) - (getRowData(a, propName) ? 1 : 0) || 0;
      default:
        return 0;
    }
  });
};

export { sortReportData, sortTurnoverReportData };
