import { Anchor, Box, Burger, Group } from "@mantine/core";
import classNames from "classnames";
import PropertySwitch from "components/PropertySwitch";
import { FC, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useGroupStore } from "stores/group";
import styles from "../Sidebar.module.scss";
import ValkSmallLogo from "assets/images/LogoTogoSmall";
import Navbar from "components/navbar";

interface MobileProps {
  sidebarClasses: string;
}
const MobileNavigation: FC<MobileProps> = () => {
  const { currentGroup } = useGroupStore();
  const ref = useRef<HTMLButtonElement>(null);
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  return (
    <Box className={styles["mobile-burger"]}>
      <Group>
        <Anchor
          component={Link}
          to={`/${currentGroup?.slug}`}
          onClick={() => setIsBurgerOpen(false)}
          className={styles["mobile-logo"]}>
          <ValkSmallLogo className={styles["mobile-logo"]} />
        </Anchor>
        <Group justify="right" className={styles["button-container"]} mb={isBurgerOpen ? 24 : 0}>
          <PropertySwitch />
          <Burger
            c="white"
            ml="sm"
            mr="sm"
            ref={ref}
            opened={isBurgerOpen}
            onClick={() => setIsBurgerOpen((prev) => !prev)}
          />
        </Group>
      </Group>
      <Box
        className={classNames(styles["mobile-burger-content"], {
          [styles["mobile-burger-content-open"]]: isBurgerOpen,
        })}>
        <Navbar />
      </Box>
    </Box>
  );
};

export default MobileNavigation;
