import { create } from "zustand";
import { AllowedProperty, Property } from "types";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type LocationStoreProps = {
  currentLocation: Property | AllowedProperty | null;
  setCurrentLocation: (location: Property | AllowedProperty | null) => void;
};

export const useLocationStore = create<LocationStoreProps>()(
  devtools(
    persist(
      (set) => ({
        currentLocation: null,
        setCurrentLocation: (currentLocation) => set({ currentLocation }),
      }),
      {
        name: "location-storage",
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);
