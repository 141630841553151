import { FC } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { useGroupStore } from "stores/group";
import { useLocationStore } from "stores/location";
import { User, UserRoles } from "types";

interface AdminRoutesProps {
  user?: User;
  loading?: boolean;
}
const AdminRoutes: FC<AdminRoutesProps> = ({ user, loading }) => {
  const { currentGroup } = useGroupStore();
  const { currentLocation, setCurrentLocation } = useLocationStore();

  if (loading) return null;

  if (currentLocation) setCurrentLocation(null);
  if (user?.role === UserRoles.admin) return <Outlet />;

  return user?.allowedProperties.length ? (
    <Navigate to={`/${currentGroup?.slug}/${user?.allowedProperties[0]?.slug}`} />
  ) : (
    <Navigate to="/login" />
  );
};

export default AdminRoutes;
