import { ComboboxItem } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import { useMemo } from "react";
import api from "services/api";

const useCurrentUser = () => {
  const {
    data: currentUser,
    isLoading,
    isError,
  } = useQuery(["currentUser"], () => api.account.getCurrentUser(), {
    select: (data) => data.data,
  });

  const getAllowedProperty = (id: number) =>
    currentUser?.allowedProperties.find((property) => property.id === id);

  const allowedPropertiesList: ComboboxItem[] = useMemo(() => {
    const properties = currentUser?.allowedProperties
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((property) => ({
        value: String(property.id),
        label: property.name,
      }));
    return properties ?? [];
  }, [currentUser]);

  return {
    currentUser,
    isLoading,
    isError,
    allowedPropertiesList,
    getAllowedProperty,
  };
};

export default useCurrentUser;
