import { HiOutlineStatusOffline, HiOutlineStatusOnline } from "react-icons/hi";

interface PortStatusProps {
  online: boolean;
}

const PortStatus: React.FC<PortStatusProps> = ({ online }) =>
  online ? (
    <HiOutlineStatusOnline size={18} color="green" />
  ) : (
    <HiOutlineStatusOffline size={18} color="red" />
  );

export default PortStatus;
