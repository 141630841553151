import dayjs from "dayjs";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

type UiStoreProps = {
  state: string | null;
  shopId: string | null;
  startAt: string | null;
  endAt: string | null;
  assortmentTab: string | null;
  priceLabelTab: string | null;
  isNavFolded: boolean;
  setState: (state: string | null) => void;
  setShop: (shop: string | null) => void;
  setQueryDates: (dates: Date[]) => void;
  resetProperty: () => void;
  setAssortmentTab: (assortment: string | null) => void;
  setPriceLabelTab: (priceLabel: string | null) => void;
  foldSidebar: (folded: boolean) => void;
};

export const useUiStorage = create<UiStoreProps, any>(
  devtools(
    persist(
      (set) => ({
        state: "",
        shopId: "",
        startAt: dayjs().startOf("day").toISOString(),
        endAt: dayjs().endOf("day").toISOString(),
        assortmentTab: "categories",
        priceLabelTab: "table",
        isNavFolded: false,
        setState: (state) => set({ state }),
        setShop: (shopId) => set({ shopId }),
        setQueryDates: (dates) => {
          set({
            startAt: dates[0] ? dayjs(dates[0]).toISOString() : null,
            endAt: dates[1] ? dayjs(dates[1]).toISOString() : null,
          });
        },
        setAssortmentTab: (assortmentTab) => set({ assortmentTab }),
        setPriceLabelTab: (priceLabelTab) => set({ priceLabelTab }),
        resetProperty: () => set({ state: "", shopId: "" }),
        foldSidebar: (folded) => set({ isNavFolded: folded }),
      }),
      { name: "ui-storage", storage: createJSONStorage(() => sessionStorage) }
    )
  )
);
