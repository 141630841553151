import { lazy } from "react";
import { Route } from "react-router-dom";

const AccountOverview = lazy(() => import("pages/account/AccountOverview"));

const AccountSettings = lazy(() => import("pages/account/settings/AccountSettings"));
const CategoryOverview = lazy(() => import("pages/category/CategoryOverview"));

const CategorySettings = lazy(() => import("pages/category/settings/CategorySettings"));
const ProductOverview = lazy(() => import("pages/product/ProductOverview"));

const ProductSettings = lazy(() => import("pages/product/settings/ProductSettings"));
const TransactionOverview = lazy(() => import("pages/transaction/TransactionOverview"));
const TransactionSettings = lazy(() => import("pages/transaction/settings/TransactionSettings"));
const LedgerOverview = lazy(() => import("pages/administration/ledger/LedgerOverview"));
const LedgerSettings = lazy(() => import("pages/administration/ledger/LedgerSettings"));

const ReportOverview = lazy(() => import("pages/report/ReportOverview"));
const CategoryReportOverview = lazy(() => import("pages/report/category/CategoryReportOverview"));

const ReviewOverview = lazy(() => import("pages/review/ReviewOverview"));

const BaseLocations = (
  <>
    <Route path="accounts">
      <Route index element={<AccountOverview />} />
      <Route path="create" element={<AccountSettings />} />
      <Route path="edit/:userid" element={<AccountSettings />} />
    </Route>
    <Route path="categories">
      <Route index element={<CategoryOverview />} />
      <Route path="create" element={<CategorySettings />} />
      <Route path="edit/:categoryId" element={<CategorySettings />} />

      <Route path=":categoryId/products">
        <Route index element={<ProductOverview />} />
        <Route path="create" element={<ProductSettings />} />
        <Route path="edit/:productId" element={<ProductSettings />} />
      </Route>

      <Route path="products">
        <Route path="create" element={<ProductSettings />} />
        <Route path="edit/:productId" element={<ProductSettings />} />
      </Route>
    </Route>
    <Route path="transactions">
      <Route index element={<TransactionOverview />} />
      <Route path=":transactionId" element={<TransactionSettings />} />
    </Route>
    <Route path="administration">
      <Route path="ledgers">
        <Route index element={<LedgerOverview />} />
        <Route path=":ledgerId" element={<LedgerSettings />} />
      </Route>
    </Route>
    <Route path="reviews">
      <Route index element={<ReviewOverview />} />
    </Route>
    <Route path="dashboard">
      <Route index element={<ReportOverview />} />
      <Route path="category/:categoryId" element={<CategoryReportOverview />} />
    </Route>
  </>
);

export default BaseLocations;
