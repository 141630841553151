import { useQuery } from "@tanstack/react-query";
import { Category, Product } from "types";
import api from "services/api";
import { useLocationStore } from "stores/location";

interface UseProductsQueryOptions {
  categoryId?: Category["id"];
  enabled?: boolean;
  onSuccess?: (data: Product[]) => void;
}

const useProductsQuery = ({ categoryId, ...restOptions }: UseProductsQueryOptions = {}) => {
  const locationId = useLocationStore((state) => state.currentLocation?.id);
  return useQuery(
    ["products", locationId ?? "admin", categoryId],
    async () => api.product.getAll(categoryId),
    {
      select: (data) => data?.data,
      ...restOptions,
    }
  );
};

export default useProductsQuery;
