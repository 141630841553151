import { FC, SVGProps } from "react";

const Star: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M22.1876 10.8333C22.5001 10.5208 22.2917 10 21.8751 9.89583L16.0417 9.0625C15.8334 9.0625 15.7292 8.95833 15.6251 8.75L13.0209 3.4375C12.9167 3.22917 12.7084 3.125 12.5001 3.125C12.2917 3.125 12.0834 3.22917 11.9792 3.4375L9.37507 8.75C9.2709 8.85417 9.16674 8.95833 8.95841 9.0625L3.12507 9.89583C2.70841 10 2.50007 10.5208 2.81257 10.8333L7.08341 15C7.18757 15.1042 7.29174 15.3125 7.29174 15.5208L6.25007 21.3542C6.14591 21.5625 6.45841 21.875 6.77091 21.875C6.87507 21.875 6.97924 21.875 6.97924 21.7708L12.1876 19.0625C12.2917 19.0625 12.3959 18.9583 12.3959 18.9583C12.5001 18.9583 12.6042 18.9583 12.6042 19.0625L17.8126 21.7708C17.9167 21.7708 18.0209 21.875 18.0209 21.875C18.3334 21.875 18.6459 21.5625 18.5417 21.25L17.5001 15.4167C17.5001 15.2083 17.5001 15.1042 17.7084 14.8958L22.1876 10.8333Z"
        fill="black"
      />
    </svg>
  );
};

export default Star;
