import { Shop } from "types";
import { useQuery } from "@tanstack/react-query";
import api from "services/api";

const usePriceLabelQuery = (shopId?: Shop["id"]) => {
  return useQuery(["priceLabels", shopId], () => api.priceLabels.getAll(shopId!), {
    select: (data) => data.data,
    enabled: shopId !== undefined,
  });
};

export default usePriceLabelQuery;
