import React, { FC } from "react";
import { Box, Group } from "@mantine/core";

import PropertySwitch from "components/PropertySwitch";

type ContentHeaderProps = {
  children: React.ReactNode;
  withPropertySwitch?: boolean;
  horizontal?: boolean;
};

const ContentHeader: FC<ContentHeaderProps> = ({
  children,
  withPropertySwitch = false,
  horizontal = false,
}) => {
  return (
    <Group mb={24} justify="space-between">
      {horizontal ? <>{children}</> : <Box>{children}</Box>}
      {withPropertySwitch && <PropertySwitch />}
    </Group>
  );
};

export default ContentHeader;
