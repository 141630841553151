import { Badge as MBadge, BadgeProps as MBadgeProps } from "@mantine/core";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface BadgeProps extends MBadgeProps {
  type: "concept" | "ownProduct" | "location";
  title?: string;
}

const Badge: FC<BadgeProps> = (props) => {
  const { t } = useTranslation();

  switch (props.type) {
    case "concept":
      return (
        <MBadge {...props} variant="light" color="yellow">
          {t("concept")}
        </MBadge>
      );
    case "ownProduct":
      return (
        <MBadge {...props} variant="light" color="cyan">
          {t("product.locationAdded")}
        </MBadge>
      );
    case "location":
      return (
        <MBadge {...props} variant="light" color="blue">
          {props.title}
        </MBadge>
      );
  }
};

export default Badge;
