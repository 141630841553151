import { FC } from "react";
import Navbar from "components/navbar";
import { useUiStorage } from "stores/ui";
import { Link, useParams } from "react-router-dom";
import { Anchor } from "@mantine/core";
import ValkLogo from "assets/images/LogoTogoLight";
import ValkSmallLogo from "assets/images/LogoTogoSmall";
import styles from "../Sidebar.module.scss";
import useLinkBuilder from "hooks/useLinkBuilder";
import { UserRoles } from "types";
import useCurrentUser from "utils/currentUser";

interface NavigationProps {
  sidebarClasses: string;
}
const SideNavigation: FC<NavigationProps> = ({ sidebarClasses }) => {
  const { isNavFolded } = useUiStorage();
  const linkBuilder = useLinkBuilder();

  const isOnMain = window.location.pathname.includes("/dashboard");

  const navigateToMain = isOnMain ? "" : linkBuilder("");

  const { currentUser } = useCurrentUser();
  const { groupId } = useParams();

  const path = currentUser?.role === UserRoles.admin ? `${groupId}/dashboard` : navigateToMain;

  return (
    <div className={sidebarClasses}>
      <Anchor component={Link} to={path}>
        {isNavFolded ? (
          <ValkSmallLogo className={styles.smallLogo} />
        ) : (
          <ValkLogo className={styles.logo} />
        )}
      </Anchor>
      <Navbar />
    </div>
  );
};
export default SideNavigation;
