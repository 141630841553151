import { Footer, TableOptions } from "./TableTypes";
import { FC } from "react";
import TableCell from "./profiles/TableCell";
import styles from "./Report.module.scss";
import { Table } from "@mantine/core";

interface TableFooterProps {
  footer: Footer<TableOptions>;
  headings: string[];
}
const TableFooter: FC<TableFooterProps> = ({ footer, headings }) => {
  return (
    <Table.Tr className={styles["table-footer"]}>
      {headings.map((heading) => {
        const value = footer[heading];
        return !!value ? (
          <TableCell
            key={heading}
            profile={value.type}
            align={value.align}
            alignContent={value?.alignContent}
            propName={heading}
            footerData={value.data}
            footer
          />
        ) : (
          <Table.Td key={heading} />
        );
      })}
    </Table.Tr>
  );
};

export default TableFooter;
