import { FC, ReactNode } from "react";
import { useQuery } from "@tanstack/react-query";

import api from "services/api";
import { Permissions, User, UserRoles } from "types";

export const permissionsTable: Permissions = {
  account: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  category: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  product: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  qrcode: {
    read: [UserRoles.admin, UserRoles.manager, UserRoles.default],
    write: [UserRoles.admin, UserRoles.manager, UserRoles.default],
    delete: [UserRoles.admin, UserRoles.manager, UserRoles.default],
  },
  location: {
    read: [UserRoles.admin],
    write: [UserRoles.admin],
    delete: [UserRoles.admin],
  },
  shop: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  administration: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  transaction: {
    read: [UserRoles.admin, UserRoles.manager, UserRoles.default],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  report: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  voucher: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  digitalPriceLabels: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  priceLabelConfig: {
    read: [UserRoles.admin],
    write: [UserRoles.admin],
    delete: [UserRoles.admin],
  },
  reviews: {
    read: [UserRoles.admin, UserRoles.manager],
    write: [UserRoles.admin, UserRoles.manager],
    delete: [UserRoles.admin, UserRoles.manager],
  },
  pickup: {
    read: [UserRoles.admin],
    write: [UserRoles.admin],
    delete: [UserRoles.admin],
  },
};

type PermissionWrapperProps = {
  children: ReactNode;
  permission: keyof Permissions;
  action: keyof Permissions[keyof Permissions];
};

const PermissionWrapper: FC<PermissionWrapperProps> = ({ children, permission, action }) => {
  const { data: user, isLoading } = useQuery<{ data: User }>(
    ["currentUser"],
    api.account.getCurrentUser
  );

  if (isLoading) return null;

  const hasPermission = permissionsTable[permission][action].includes(
    user?.data.role ?? UserRoles.default
  );

  return hasPermission ? <>{children}</> : null;
};

export default PermissionWrapper;
