import { PasswordInput, TextInput as MTextInput } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";
import { ComponentProps, FC, HTMLInputTypeAttribute } from "react";
import { AllFormKeys } from "types/formValues";
import { getTranslationObject } from "utils/getTranslation";

interface TextInputProps {
  name: string;
  form: UseFormReturnType<AllFormKeys>;
  translationString: string;
  required?: boolean;
  testId?: string;
  disabled?: boolean;
  type?: HTMLInputTypeAttribute;
  mb?: number | string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  icon?: React.ReactNode;
  size?: string;
  showValue?: boolean;
  inputRef?: ComponentProps<typeof MTextInput>["ref"];
}

const TextInput: FC<TextInputProps> = ({
  name,
  form,
  translationString,
  required = false,
  testId,
  disabled = false,
  type = "text",
  mb = "md",
  className,
  onChange,
  icon,
  size = "sm",
  showValue = true,
  inputRef,
}) => {
  const translation = getTranslationObject(translationString);

  if (type === "password")
    return (
      <PasswordInput
        disabled={disabled}
        label={translation?.title}
        fz={size}
        placeholder={translation?.placeholder ? translation.placeholder : ""}
        withAsterisk={required}
        mb={mb}
        {...form.getInputProps(name)}
        data-cy={testId}
        ref={inputRef}
      />
    );

  return (
    <MTextInput
      {...(disabled
        ? {
            disabled: true,
          }
        : {})}
      type={type}
      fz={size}
      label={translation?.title}
      placeholder={translation?.placeholder ? translation.placeholder : ""}
      withAsterisk={required}
      mb={mb}
      {...form.getInputProps(name)}
      value={showValue ? form.getInputProps(name).value : ""}
      data-cy={testId}
      className={className}
      onChange={(e) => {
        onChange ? onChange(e) : form.getInputProps(name).onChange(e);
      }}
      leftSection={icon}
      description={translation?.description}
      ref={inputRef}
    />
  );
};

export default TextInput;
