type MoneyFormattingOptions = {
  hideEuroSign?: boolean;
};

const formatMoney = (amount: number | undefined, attributes?: MoneyFormattingOptions): string => {
  const { hideEuroSign } = attributes ?? {};
  if (amount === undefined) return "-";
  const formattedAmount = amount.toFixed(2).split(".").join(",");
  return hideEuroSign ? formattedAmount : `€ ${formattedAmount}`;
};

export default formatMoney;
