import { Center, Text, TextProps, UnstyledButton } from "@mantine/core";
import i18next from "i18next";
import { FC, useState } from "react";
import { HiChevronDown, HiChevronUp, HiSelector } from "react-icons/hi";

interface TableHeadingProps {
  testId?: string;
  heading: string;
  sortingIndex?: string;
  handleSorting: (reversed: boolean, index?: string) => void;
  countOfLines: number;
  alignment: TextProps["ta"];
}
const TableHeading: FC<TableHeadingProps> = ({
  testId,
  heading,
  sortingIndex,
  handleSorting,
  countOfLines,
  alignment,
}) => {
  const [reversedSorting, setReversedSorting] = useState<boolean>(true);
  const isSorting = sortingIndex === heading;
  const Icon =
    isSorting && countOfLines > 0 ? (reversedSorting ? HiChevronDown : HiChevronUp) : HiSelector;

  return (
    <UnstyledButton
      onClick={() => {
        const reversed = isSorting ? !reversedSorting : true;
        setReversedSorting(reversed);
        handleSorting(reversed, heading);
      }}
      data-cy={`${testId}-${heading.replace(".", "-")}`}>
      <Center>
        <Text fw={700} fz="sm" c="gray.7" mr="md" ta={alignment}>
          {i18next.getResource(i18next.language, "translation", heading) ?? heading}
        </Text>
        <Icon />
      </Center>
    </UnstyledButton>
  );
};

export default TableHeading;
