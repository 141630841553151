import { FC } from "react";
import { ReportPropertyTotals } from "types";
import styles from "./Report.module.scss";
import TableCell from "./profiles/TableCell";
import { TableHeadings, TableOptions } from "./TableTypes";
import { Table } from "@mantine/core";

interface TableRowProps {
  testId?: string;
  headings: TableHeadings<TableOptions>;
  rowData: TableOptions;
  rowIndex: number;
  shop?: Omit<ReportPropertyTotals, "shops">;
  shopIndex?: number;
  styling?: "report";
}

const TableRow: FC<TableRowProps> = ({
  testId,
  headings,
  rowData,
  rowIndex,
  shop,
  shopIndex,
  styling,
}) => {
  return (
    <Table.Tr
      data-cy={`${testId}-row`}
      key={rowIndex}
      className={styles[`table-row${styling ? `-${styling}` : ""}`]}>
      {Object.entries(headings).map(([key, value]) => {
        return (
          <TableCell
            testId={`${testId}-cell`}
            key={key}
            profile={value.type}
            align={value.align}
            alignContent={value?.alignContent}
            propName={key}
            rowData={rowData}
            extraData={{ shopIndex, shop }}
          />
        );
      })}
    </Table.Tr>
  );
};

export default TableRow;
